import React, { useEffect, useState } from 'react';
import './ContactUs.scss';
import { FxInput, http, FxTextarea, AlertService, session, formValidator, FxMobile, localstorage } from '../fx-core';
import Button from '@material-ui/core/Button';

const ContactUs = (props) => {

  let defaultItem = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    message: ""
  };
  const [item, setItem] = useState(defaultItem);
  const [errors, setErrors] = useState({});
  const [countryCode, setCountryCode] = useState([]);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  //Validation area starts
  const validationMap_Item = {
    firstName: ["required"],
    mobile: ["required"],
    email: ["required", "email"],
    message: ["required"]
  };

  const getValidations = () => {
    let validationMap;
    validationMap = validationMap_Item;
    return validationMap;
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidations();
    for (var key in validationMap) {
      let result = { name: key, value: item[key], validations: validationMap[key] }
      items.push(result);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends

  const initLookup = () => {
    let inputData = {
      lookups: {
        CountryCode: { default: false }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setCountryCode(response.data.CountryCode);
    })
  }

  const uid = localstorage.getItem('userId');
  const getCustomerInfo = (id: any) => {
    let apiOptions: any = {
      url: `user/profile/${id}`
    };
    http.get(apiOptions).then(res => {
      setItem(res.data);
    })
  };

  const saveItem = () => {
    const isValid = bulkValidate();
    if (isValid) {
      let inputData = {
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        mobile: item.mobile,
        message: item.message,
      }
      let apiOptions: any = {
        url: 'support/tickets',
        data: inputData
      };
      http.post(apiOptions).then(response => {
        AlertService.showSuccessMsg();
        setItem(defaultItem);
      })
    }

  }

  useEffect(() => {
    initLookup();
    getCustomerInfo(uid);
  }, []);

  console.log("item : ", item);

  return (
    <div className="container pt-5 mt-5 mb-4">
      <div className='text-center pt-3'>
        <b>Call Us :</b> 9626 777 360
      </div>
      <div className='text-center pt-3'>
        <b>Email :</b> hello@localites.in
      </div>
      <div className="pt-4 m-1">
        <FxInput name="firstName" variant="outlined" label="First Name" fullWidth
          size="small" value={item.firstName} onValueChange={handleInputChange}
        />
        {
          hasError("firstName", "required") &&
          <div className="validation-msg">Field is required</div>
        }
      </div>
      <div className="pt-4 m-1">
        <FxInput name="lastName" variant="outlined" label="Last Name" fullWidth
          size="small" value={item.lastName} onValueChange={handleInputChange}
        />
      </div>
      <div className="pt-4 m-1">
        <FxMobile value={item.mobile} onValueChange={handleInputChange} countryCode={countryCode} />

        {hasError("mobile", "required") && (
          <div className="validation-msg">Field is required</div>
        )}
        {
          hasError("mobile", "mobileno") &&
          <div className="validation-msg">Please enter valid mobile number</div>
        }
      </div>
      <div className="pt-4 m-1">
        <FxInput name="email" variant="outlined" label="Email"
          fullWidth size="small" value={item.email} onValueChange={handleInputChange}
        />
        {
          hasError("email", "required") &&
          <div className="validation-msg">Field is required</div>
        }
        {
          hasError("email", "email") &&
          <div className="validation-msg">Please enter valid email id</div>
        }
      </div>
      <div className="pt-4 m-1">
        <FxTextarea name="message" variant="outlined" fullWidth size="small" label="Message"
          value={item.message} onValueChange={handleInputChange} maxRows={4} />
        {
          hasError("message", "required") &&
          <div className="error">Message is required</div>
        }
      </div>
      <div className="pt-5 pb-4 m-1">
        <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveItem}><b>Save</b></Button>
      </div>
    </div>
  )
};

export default ContactUs;