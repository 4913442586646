import React, { useEffect, useState } from 'react';
import './ItemSaleDashboard.scss';
import { http, FxCurrency, useNavigationService, FxDateDisplay, FxHumanDateDisplay, formatService } from '../../fx-core';
import accountbalance from '../../../assets/accountbalance.svg';
import todaysincome from '../../../assets/todaysincome.svg';
import openorders from '../../../assets/openorders.svg';
import deliveryorders from '../../../assets/readydelivery.svg';
import PendingOrders from '../PendingOrders/PendingOrders';
import Button from '@material-ui/core/Button';
import PendingSaleOrders from '../PendingSaleOrders/PendingSaleOrders';

const ItemSaleDashboard = (props: any) => {
  const { navigate, pageState } = useNavigationService();

  const defaultSD = {
    subscriptionEndDate: "",
    isExpired: false
  };
  const defaultAC = {
    currentBalance: ""
  };
  const defaultLBD = {
    listedOn: ""
  };
  const defaultLS = {
    liveStreamCount: 0
  };
  const defaultTI = {
    totalAmount: 0
  };
  const defaultOpenOrder = {
    openOrdersCount: 0
  };
  const defaultSO = {
    shippedOrdersCount: 0
  };
  const defaultBooking = {
    bookingCount: 0
  };
  const defaultPendingSaleOrders = {
    pendingSaleOrdersCount: 0
  };
  const defaultShippedSaleOrders = {
    shippedSaleOrdersCount: 0
  }

  const [subscriptionDetails, setSubscriptionDetails] = useState(defaultSD);
  const [accountCredits, setAccountCredits] = useState(defaultAC);
  const [localBusinessDirectory, setLocalBusinessDirectory] = useState(defaultLBD);
  const [liveShopping, setLiveShopping] = useState(defaultLS);
  const [totalIncome, setTotalIncome] = useState(defaultTI);
  const [openOrdersCount, setOpenOrdersCount] = useState(defaultOpenOrder);
  const [shippedOrdersCount, setShippedOrdersCount] = useState(defaultSO);
  const [bookingCount, setBookingCount] = useState(defaultBooking);
  const [pendingSaleOrders, setPendingSaleOrders] = useState(defaultPendingSaleOrders);
  const [shippedSaleOrders, setShippedSaleOrders] = useState(defaultShippedSaleOrders);

  const getDashboardDetails = () => {
    let inputData = {
      ds: {
        resultset: [
          { key: "subscriptionDetails", type: "single" },
          { key: "accountCredits", type: "single" },
          { key: "localBusinessDirectory", type: "single" },
          { key: "liveShopping", type: "single" },
          { key: "totalIncome", type: "single" },
          { key: "openOrders", type: "single" },
          { key: "shippedOrders", type: "single" },
          { key: "bookingCount", type: "single" },
          { key: "pendingSaleOrders", type: "single" },
          { key: "shippedSaleOrders", type: "single" }
        ]
      },
      data: {
        stallId: props.stallId,
        communityId: localStorage.getItem("communityId")
      }
    };
    let apiOptions: any = {
      url: 'general/dbo/getproviderdashboarddetails',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      const subsDetails = result.subscriptionDetails;
      let isExpired = true;
      if (subsDetails.subscriptionEndDate) {
        const currentDate = formatService.getCurrentDateForServer();
        isExpired = formatService.isBefore(currentDate, subsDetails.subscriptionEndDate);
      }
      const subsObj = {
        subscriptionEndDate: subsDetails.subscriptionEndDate,
        isExpired: isExpired
      };
      setSubscriptionDetails(subsObj);
      setAccountCredits(result.accountCredits);
      setLocalBusinessDirectory(result.localBusinessDirectory);
      setLiveShopping(result.liveShopping)
      setTotalIncome(result.totalIncome);
      setOpenOrdersCount(result.openOrders);
      setShippedOrdersCount(result.shippedOrders);
      setBookingCount(result.bookingCount);
      setPendingSaleOrders(result.pendingSaleOrders);
      setShippedSaleOrders(result.shippedSaleOrders);
    })
  };

  const loadDashboard = () => {
    getDashboardDetails();
  }

  const openOrders = (type) => {
    let navData = {
      targetRoute: '/provider/stallorders/' + props.stallId + '/' + type,
      targetTitle: 'Orders',
      sourceState: {},
      eventSource: {},
    };
    navigate(navData);
  }

  const openMoreOrders = () => {
    let navData = {
      targetRoute: '/provider/providerordercontainer/' + props.stallId + '/' + true + '/itemsale',
      targetTitle: 'Orders',
      sourceState: {},
      eventSource: {},
    };
    navigate(navData);
  }

  const openSubscriptions = () => {
    let navData = {
      targetRoute: '/provider/subscriptions/' + props.stallId + '/pd',
      targetTitle: "Provider Subscriptions",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const openAccounts = () => {
    let navData = {
      targetRoute: '/provider/accounts/' + props.stallId + '/pd',
      targetTitle: "Provider Accounts",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const openLiveStreams = () => {
    let navData = {
      targetRoute: '/provider/livestreams',
      targetTitle: "Live Streams",
      targetData: { stallId: props.stallId },
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const openLocalDrList = () => {
    let navData = {
      targetRoute: '/localdrlist/' + props.stallId,
      targetTitle: "Local Directory List",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const openBookings = () => {
    let navData = {
      targetRoute: '/provider/stallbookings/' + props.stallId,
      targetTitle: 'Bookings',
      sourceState: {},
      eventSource: {},
    };
    navigate(navData);
  }

  const openMoreSaleOrders = () => {
    let navData = {
      targetRoute: '/provider/providerordercontainer/' + props.stallId + '/' + true + '/listing',
      targetTitle: 'Listing Orders',
      sourceState: {},
      eventSource: {},
    };
    navigate(navData);
  }

  const openSaleOrders = (type) => {
    let navData = {
      targetRoute: '/provider/dashboardsaleorders/' + props.stallId + '/' + type,
      targetTitle: 'Sale Orders',
      sourceState: {},
      eventSource: {},
    };
    navigate(navData);
  }

  useEffect(() => {
    getDashboardDetails();
  }, [props.stallId]);

  const body = (
    <div className="itemsaledashboard-ui">
      <div className="content-container">
        <div className="content">
          <div className="row py-4 ourservicerow justify-content-center">
            {/* <div className='col-2 servicecard pointer' onClick={openAccounts}>
              <div className="accountbalance wd40">
                <img src={accountbalance} alt="account credit" />
              </div>
              <div className="cardlbl">Account Credit</div>
              <div className="cardvalue">
                <FxCurrency value={accountCredits.currentBalance} />
              </div>
            </div> */}
            <div className='col-2 servicecard pointer' onClick={openMoreOrders}>
              <div className="todaysincome wd40">
                <img src={todaysincome} alt="todays income" />
              </div>
              <div className="cardlbl">Total Income <br />(for the month)</div>
              <div className="cardvalue">
                <FxCurrency value={totalIncome.totalAmount} />
              </div>
            </div>
            <div className='col-2 servicecard pointer' onClick={openSubscriptions}>
              <div className="subscription wd40">
                <i className="fas fa-hand-holding-usd"></i>
              </div>
              <div>
                <div className="cardlabel">Subscription</div>
                <div className="cardvaluewithtext">
                  {
                    !subscriptionDetails.isExpired &&
                    <div>
                      <div>
                        Active
                      </div>
                      <div>
                        <span className='mr-1'>Expiring</span>
                        <FxHumanDateDisplay value={subscriptionDetails.subscriptionEndDate} displayType="date" />
                      </div>
                    </div>
                  }
                  {
                    subscriptionDetails.isExpired &&
                    <div>
                      <div>
                        In Active
                      </div>
                      {
                        subscriptionDetails.subscriptionEndDate &&
                        <div>
                          <span>Expired on <FxDateDisplay value={subscriptionDetails.subscriptionEndDate} /></span>
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className='col-2 servicecard pointer' onClick={openLocalDrList}>
              <div className="localbusiness wd40">
                <i className="far fa-address-book"></i>
              </div>
              <div>
                <div className="cardlabel">Local Business Directory</div>
                <div className="cardvaluewithtext">
                  {
                    localBusinessDirectory.listedOn &&
                    <span>Listed on <FxDateDisplay value={localBusinessDirectory.listedOn} /></span>
                  }
                  {
                    !localBusinessDirectory.listedOn &&
                    <span>No Listing</span>
                  }
                </div>
              </div>
            </div>
            {/* <div className='col-2 servicecard pointer' onClick={openLiveStreams}>
              <div className="liveshopping wd40">
                <i className="fas fa-video"></i>
              </div>
              <div>
                <div className="cardlabel">Live Shopping</div>
                <div className="cardvaluewithtext">
                  <span className='pr-3'>Events</span>
                  <span>{liveShopping.liveStreamCount}</span>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row py-4 ourservicerow justify-content-center">
            <div className='col-2 servicecard pointer' onClick={() => openOrders('open')}>
              <div className="openorders wd40">
                <img src={openorders} alt="open orders" />
              </div>
              <div className="cardlbl">Open Item Orders</div>
              <div className="cardvalue">
                {openOrdersCount.openOrdersCount}
              </div>
            </div>
            {/* <div className='col-2 servicecard pointer' onClick={() => openOrders('shipped')}>
              <div className="deliveryorders wd40">
                <img src={deliveryorders} alt="delivery orders" />
              </div>
              <div className="cardlbl">Delivery Item Orders</div>
              <div className="cardvalue">
                {shippedOrdersCount.shippedOrdersCount}
              </div>
            </div> */}
            <div className='col-2 servicecard pointer' onClick={() => openSaleOrders('open')}>
              <div className="openorders wd40">
                <img src={openorders} alt="open orders" />
              </div>
              <div className="cardlbl">Open Listing Orders</div>
              <div className="cardvalue">
                {pendingSaleOrders.pendingSaleOrdersCount}
              </div>
            </div>
            {/* <div className='col-2 servicecard pointer' onClick={() => openSaleOrders('shipped')}>
              <div className="deliveryorders wd40">
                <img src={deliveryorders} alt="delivery orders" />
              </div>
              <div className="cardlbl">Delivery Sale Orders</div>
              <div className="cardvalue">
                {shippedSaleOrders.shippedSaleOrdersCount}
              </div>
            </div> */}
            <div className='col-2 servicecard pointer' onClick={() => openBookings()}>
              <div className="subscription wd40">
                <i className="fas fa-ticket-alt"></i>
              </div>
              <div className="cardlbl">Bookings</div>
              <div className="cardvalue">
                {bookingCount.bookingCount}
              </div>
            </div>
          </div>

          <div className='row'>
            <PendingOrders stallId={props.stallId} loadDashboard={loadDashboard} />
          </div>
          <div className="text-center p-3">
            {openOrdersCount && openOrdersCount.openOrdersCount > 5 && (
              <span>
                <Button variant="contained" size="small" color="primary" onClick={openMoreOrders}>
                  More Item Orders
                </Button>
              </span>
            )}

          </div>

          <div className='row mt-1'>
            <PendingSaleOrders stallId={props.stallId} loadDashboard={loadDashboard} />
          </div>
          <div className="text-center p-3">
            {
              pendingSaleOrders && pendingSaleOrders.pendingSaleOrdersCount >5 && (
                <span>
                  <Button variant="contained" size="small" color="primary" onClick={openMoreSaleOrders}>More Listing Orders</Button>
                </span>
              )
            }

          </div>

        </div>
      </div>
    </div>
  );

  return (
    <div>{body}</div>
  );

}

export default ItemSaleDashboard;