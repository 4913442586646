import React from 'react';
import './DeliverySlots.scss';
import { FxDate, AlertService } from './../../fx-core';
import { Button } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export const DeliverySlots = (props) => {

  const saveDeliverySlot = () => {
    if (props.deliverySlotId > 0) {
      props.saveDeliverySlot();
    }
    else {
      AlertService.showErrorMsg("Please select the slot.");
    }
  };

  return (
    <div className='delivery-slots'>
      {
        props.isSave &&
        <div className="row justify-content-between title-font px-3">
          <div className="py-1 pl-2">
            <span>Delivery Slots</span>
          </div>
          <div className="text-right pointer my-auto pr-2">
            <i className="fas fa-times-circle font-16" onClick={props.handleClose}></i>
          </div>
        </div>
      }
      {
        !props.isSave &&
        <div>
          <div className='font-14 bold-text pt-4'>Delivery Slots</div>
          <div className='mr-3'>
            <FxDate
              name="selectedDeliveryDate"
              variant="standard"
              size="small"
              fullWidth
              value={props.selectedDeliveryDate}
              onValueChange={props.onDeliveryDateSelect}
              minDate={props.minDate}
            />
          </div>
        </div>
      }
      {
        props.availableSlots.length > 0 &&
        <div className="row mx-1">
          {
            props.availableSlots.map((slot, i) => (
              <span className="mt-2 col-6 pl-0" key={i}>
                <button className={slot.id == props.deliverySlotId ? "slot-btn slot-btn-active" : "slot-btn"}
                  onClick={() => props.onSelectSlot(slot)}>
                  <div>{slot.description}</div>
                  <div>{slot.startTime} - {slot.endTime}</div>
                </button>
              </span>
            ))
          }
        </div>
      }
      {
        props.availableSlots.length > 0 && props.isSave &&
        <div className='text-right mr-3 mt-2 mb-3'>
          <Button variant='contained' color="primary" size='small' className='button-style' onClick={saveDeliverySlot}>Save</Button>
        </div>
      }
      {
        props.availableSlots.length === 0 &&
        <div className='text-center'>
          <span>No slots available...</span>
        </div>
      }
    </div>
  );
};