import React from 'react';
import './FeaturedStall.scss';
import Card from '@material-ui/core/Card';


const FeaturedStall = (props: any) => {

    return (
        <div className="featuredStall-ui pt-5" onClick={() => props.navToStall(props.item.stall.slug)}>

            {/* Desktop View */}
            <div className="deskview">
                <Card className="pointer" style={{ width: "250px", height: "250px" }}>
                    <div className="p-5">
                        <div className="text-center">
                            <div className="font-20">{props.item.stall.name}</div>
                            <div className="font-14 text-success py-1"><b>
                                {props.item.stall.stallOpenStatus == 'Open' ?
                                    <span className="text-success">{props.item.stall.stallOpenStatus}</span>
                                    :
                                    <span className="text-danger">{props.item.stall.stallOpenStatus}</span>
                                }
                            </b></div>
                            <div className="font-14 pb-3">{props.item.stall.description}</div>
                            {/* <div className="font-20">{props.item.stallItemCount} Items</div> */}
                        </div>
                    </div>
                </Card>
            </div>

            {/* Tablet View */}
            <div className="tabview">
                <Card className="pointer" style={{ backgroundColor: "#F8F5EF", width: "600px", height: "250px", color: "#6D4B40", }}>
                    <div className="p-2">
                        <div className="text-center">
                            <div className="font-22 pt-3"><b>{props.item.stall.name}</b></div>
                            <div className="font-16 text-success py-3"><b>
                                {props.item.stall.stallOpenStatus == 'Open' ?
                                    <span className="text-success">{props.item.stall.stallOpenStatus}</span>
                                    :
                                    <span className="text-danger">{props.item.stall.stallOpenStatus}</span>
                                }
                            </b></div>
                            <div className="font-16 pb-5">{props.item.stall.address}</div>
                            <div className="font-20">{props.item.stallItemCount} Items</div>
                        </div>
                    </div>
                </Card>
            </div>

            {/* Mobile View */}
            <div className="mobileview">
                <Card className="pointer" style={{ backgroundColor: "#F8F5EF", width: "300px", height: "150px", color: "#6D4B40", }}>
                    <div className="p-2">
                        <div className="text-center">
                            <div className="font-16 pt-1"><b>{props.item.stall.name}</b></div>
                            <div className="font-12 text-success py-1"><b>
                                {props.item.stall.stallOpenStatus == 'Open' ?
                                    <span className="text-success">{props.item.stall.stallOpenStatus}</span>
                                    :
                                    <span className="text-danger">{props.item.stall.stallOpenStatus}</span>
                                }
                            </b></div>
                            <div className="font-12 pb-3">{props.item.stall.address}</div>
                            <div className="font-16">{props.item.stallItemCount} Items</div>
                        </div>
                    </div>
                </Card>
            </div>

        </div>
    );
}
export default FeaturedStall;