import React, { useEffect, useState } from 'react';
import './ProviderItemOrders.scss';
import { FxDate, FxSelect, FxDateDisplay, FxCurrency, http, formatService, AlertService, general, useNavigationService, localstorage } from '../../fx-core';
import * as _ from 'lodash';
import { Button, Card, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Drawer } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ProviderItemOrderDetail from '../ProviderItemOrderDetail/ProviderItemOrderDetail';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { DeliverySlots } from '../../OrderConfirmation/DeliverySlots/DeliverySlots';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import MessageContainer from '../../MessageContainer/MessageContainer';
import { NoRecords } from '../../NoRecords/NoRecords';

const useStyles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const ProviderItemOrders = (props: any) => {
  const { navigate } = useNavigationService();
  const classes = useStyles();

  let defaultFilter: any = {
    fromDate: formatService.getMonthStartDate(),
    toDate: formatService.getCurrentDateForServer(),
    orderStatusId: -1,
    stallId: -1
  };
  const initialList = [];
  const selOrderDefaultObj = {
    id: 0,
    orderStatusId: 0,
    orderTypeId: 0
  };
  const [orderList, setOrderList] = useState(initialList);
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [filter, setFilter] = useState(defaultFilter);
  const [orderStatus, setOrderStatus] = useState(initialList);
  const [stalls, setStalls] = useState(initialList);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(selOrderDefaultObj);
  const [isCancelAction, setIsCancelAction] = useState(false);
  const [deliverySlotsOpen, setDeliverySlotsOpen] = useState(false);
  const [availableSlots, setAvailableSlots] = useState(initialList);
  const [deliverySlotId, setDeliverySlotId] = useState(0);
  const [openMessage, setOpenMessage] = React.useState(false);

  const toggleDrawerMessage = () => {
    setOpenMessage(!openMessage);
  };

  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
  };

  const handleFilterChange = (data) => {
    setFilter(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getOrderList = (options: any = {}) => {
    let inputData = {
      fromDate: formatService.getDateStringForServer(filter.fromDate),
      toDate: formatService.getDateStringForServer(filter.toDate),
      stallId: filter.stallId,
      orderStatusId: filter.orderStatusId
    };
    let apiOptions: any = {
      url: 'order/community/query',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getOrderListCallBack(res);
    })
  };

  const getOrderListCallBack = (res) => {
    setOrderList(res.data);
  };

  const viewOrderDetail = (item: any) => {
    setOpen(true);
    setOrderId(item.id);
  };

  const handleClose = () => {
    setOpen(false);
    setOrderId(0);
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        OrderStatus: { default: true },
        ProviderStalls: { default: false }
      },
      filters: {
        userId: localstorage.getItem('userId'),
        communityId: localstorage.getItem("communityId")
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      lookupCallBack(res);
    });
  };

  const lookupCallBack = (res) => {
    let orderStatusRes = res.data.OrderStatus;
    if (orderStatusRes.length > 0) {
      orderStatusRes[0].text = "All";
      let itemIdx = _.findIndex(orderStatusRes, { code: "Processing" });
      orderStatusRes.splice(itemIdx, 1);
      setOrderStatus(orderStatusRes);
    }

    let stallRes = res.data.ProviderStalls;
    setStalls(stallRes);
    if (props.stallId > 0) {
      const data = { stallId: props.stallId };
      setFilter(prevState => {
        return { ...prevState, ...data }
      });
    }
    else if (stallRes.length > 0) {
      const data = { stallId: stallRes[0].id };
      setFilter(prevState => {
        return { ...prevState, ...data }
      });
    }
  };

  const defaulConfirmDialogStateValues = () => {
    setSelectedOrder(selOrderDefaultObj);
    setMessage(null);
    setConfirmOpen(false);
    if (isCancelAction) {
      setIsCancelAction(false);
    }
  };

  const openConfirmDialogByStatus = (item) => {
    let msg = "";
    if (item.orderStatusId === 1) {
      msg = "Do you want to accept the order #" + item.orderIdentifier + "?";
    }
    else if (item.orderStatusId === 2 && item.orderTypeId === 1) {
      msg = "Do you want to ship the order #" + item.orderIdentifier + "?";
    }
    else if (item.orderStatusId === 2 && item.orderTypeId === 2) {
      msg = "Is the order #" + item.orderIdentifier + " ready?";
    }
    else if (item.orderStatusId === 4 || item.orderStatusId === 5) {
      msg = "Do you want to deliver the order #" + item.orderIdentifier + "?";
    }
    setSelectedOrder(item);
    setMessage(msg);
    setConfirmOpen(true);
  };

  const handleDialogClose = () => {
    defaulConfirmDialogStateValues()
  };

  const onConfirmCallback = (action, comments) => {
    if (selectedOrder.orderStatusId === 1) {
      if (action === 'cancel') {
        cancelOrder(comments);
      } else {
        markAsAccepted();
      }
    }
    else if (selectedOrder.orderStatusId === 2) {
      markAsReadyOrShipped();
    }
    else if (selectedOrder.orderStatusId === 4 || selectedOrder.orderStatusId === 5) {
      markOrderDelivered();
    }
  };


  const markAsAccepted = () => {
    let apiOptions = {
      url: `order/${selectedOrder.id}/markAsAccepted`
    };
    http.put(apiOptions).then(res => {
      markAsAcceptedCallBack(res);
    });
  };

  const markAsAcceptedCallBack = (res) => {
    AlertService.showMsg({ message: "Order is accepted" });
    defaulConfirmDialogStateValues();
    getOrderList();
  };

  const markAsReadyOrShipped = () => {
    let apiOptions = {
      url: `order/${selectedOrder.id}/MarkAsReadyOrShipped`
    };
    http.put(apiOptions).then(res => {
      markAsReadyOrShippedCallBack(res);
    });
  };

  const markAsReadyOrShippedCallBack = (res) => {
    let successMsg = "";
    if (selectedOrder.orderTypeId === 1) {
      successMsg = "Order shipped";
    }
    else if (selectedOrder.orderTypeId === 2) {
      successMsg = "Order ready";
    }
    AlertService.showMsg({ message: successMsg });
    defaulConfirmDialogStateValues();
    getOrderList();
  };

  const markOrderDelivered = () => {
    let inputData = {
      id: selectedOrder.id,
      currentDate: formatService.getCurrentDateForServer()
    }
    let apiOptions = {
      url: `order/MarkOrderDelivered`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      markOrderDeliveredCallBack(res);
    });
  };

  const markOrderDeliveredCallBack = (res) => {
    AlertService.showMsg({ message: "Order delivered" });
    defaulConfirmDialogStateValues();
    getOrderList();
  };

  const openConfirmDialogForCancel = (item) => {
    setSelectedOrder(item);
    setIsCancelAction(true);
    setConfirmOpen(true);
  };

  const cancelOrder = (comments) => {
    let inputData = {
      id: selectedOrder.id,
      cancelledRemarks: comments
    }
    let apiOptions = {
      url: `order/MarkOrderCancelled`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      cancelOrderCallBack(res);
    });
  };

  const cancelOrderCallBack = (res) => {
    defaulConfirmDialogStateValues();
    getOrderList();
  };

  const closedeliverySlots = () => {
    setDeliverySlotsOpen(false);
    setOrderId(0);
    setDeliverySlotId(0);
  };

  const getAvailableSlots = (item) => {
    setOrderId(item.id);
    setDeliverySlotId(item.deliverySlotId);
    let inputData = {
      stallId: item.stallId
    }
    let apiOptions = {
      url: `stalldeliveryslot/available`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getAvailableSlotsCallBack(res);
    });
  };

  const getAvailableSlotsCallBack = (res) => {
    const result = res.data;
    if (result.deliverySlots.length > 0) {
      for (let item of result.deliverySlots) {
        item.startTime = item.startTime != null ? general.getFormattedTime(item.startTime) : null;
        item.endTime = item.endTime != null ? general.getFormattedTime(item.endTime) : null;
      }
      setAvailableSlots(result.deliverySlots);
    }
    else {
      setAvailableSlots([]);
    }
    setDeliverySlotsOpen(true);
  };

  const onSelectSlot = (item) => {
    if (item.id != deliverySlotId) {
      setDeliverySlotId(item.id);
    }
    else {
      setDeliverySlotId(0);
    }
  };

  const changeDeliverySlot = () => {
    let inputData = {
      id: orderId,
      deliverySlotId: deliverySlotId
    };
    let apiOptions = {
      url: `order/updatedeliveryslot`,
      data: inputData
    };
    http.put(apiOptions).then(res => {
      changeDeliverySlotCallBack(res);
    });
  };

  const changeDeliverySlotCallBack = (res) => {
    AlertService.showMsg({ message: "Updated delivery slot" });
    closedeliverySlots();
    getOrderList();
  };

  const navToDashboard = () => {
    let navData = {
      targetRoute: '/provider/provdashboard/' + filter.stallId,
      targetTitle: "Provider Dashboard",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const navToMessage = (orderId) => {
    setOrderId(orderId);
    setOpenMessage(true);
  }

  const handleMessageClose = () => {
    setOpenMessage(false);
    setOrderId(0);
  }

  useEffect(() => {
    initLookup();
  }, []);

  useEffect(() => {
    if (filter.stallId > 0)
      getOrderList();
  }, [filter])


  const body = (
    <div className="provideritemorders-ui pt-3">
      <div className="content-container">
        <div className="content">
          {props.isFromOrders &&
            <div className="title-font-breadcrumbs custom-head pl-4">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToDashboard}>
                  <b>Provider Dashboard</b>
                </Link>
                <Typography color="primary" className="breadcrumbs-style">More Orders</Typography>
              </Breadcrumbs>
            </div>
          }
          {/* Desktop View */}
          <div className='deskview'>
            <div className="row mt-2 px-5 justify-content-between">
              <div className="col-3 px-0">
                <FxDate
                  name="fromDate"
                  variant="standard"
                  size="small"
                  label="From Date"
                  value={filter.fromDate}
                  onValueChange={handleInputChange}
                />
              </div>
              <div className="col-3 px-0">
                <FxDate
                  name="toDate"
                  variant="standard"
                  size="small"
                  label="To Date"
                  value={filter.toDate}
                  onValueChange={handleInputChange}
                />
              </div>
              <div className="col-3 px-0 pt-4">
                <FxSelect name="orderStatusId" variant="outlined" label="Order Status"
                  options={orderStatus}
                  selectedValue={filter.orderStatusId}
                  valueField="id"
                  displayField="text"
                  size="small"
                  style={{ width: 260 }}
                  onValueChange={handleInputChange} />
              </div>
              <div className="col-3 px-0 pt-4">
                <FxSelect name="stallId" variant="outlined" label="Stall"
                  options={stalls}
                  selectedValue={filter.stallId}
                  valueField="id"
                  displayField="text"
                  size="small"
                  style={{ width: 260 }}
                  onValueChange={handleInputChange} />
              </div>
            </div>

            <div className='my-4 mx-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Shop</TableCell>
                      <TableCell>Delivery Type</TableCell>
                      <TableCell>Delivery Date</TableCell>
                      <TableCell>Consumer</TableCell>
                      <TableCell className='text-right'>Order#</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell className='text-right'>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderList.length == 0 &&
                      <TableCell colSpan={11}>
                        <NoRecords />
                      </TableCell>
                    }
                    {orderList.length > 0 && orderList.map((row: any, i) => (
                      <TableRow>
                        <TableCell><FxDateDisplay value={row.placedDate} /></TableCell>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>
                          {row.orderTypeId == 1 &&
                            <span>Delivery</span>
                          }
                          {row.orderTypeId == 2 &&
                            <span>Pickup</span>
                          }
                        </TableCell>
                        <TableCell><FxDateDisplay value={row.orderDate} /></TableCell>
                        <TableCell>
                          <div className='row'>
                            <span>{row.consumerName}</span>
                            <span className='pl-2'>{row.consumerPhone}</span>
                          </div>
                        </TableCell>
                        <TableCell className='text-right'>#{row.orderIdentifier}</TableCell>
                        <TableCell>
                          {row.orderStatus}
                          {
                            row.orderStatus === 'Cancelled' &&
                            <span className='pl-2'>({row.cancelledRemarks})</span>
                          }
                        </TableCell>
                        <TableCell className='text-right'><FxCurrency value={row.orderTotal} /></TableCell>
                        <TableCell>
                          {row.orderPayments && row.orderPayments.map((itemObj, i) => (
                            <div>
                              {itemObj.paymentMode == 'cod' &&
                                <span className='payment-style'>
                                  Cash / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode == 'stripe' &&
                                <span className='payment-style'>
                                  {'Card :' + itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'stripe' &&
                                <span className='payment-style'>
                                  {itemObj.paymentMode} / {itemObj.paymentStatus}
                                </span>
                              }
                            </div>
                          ))
                          }
                        </TableCell>
                        <TableCell>
                          {
                            (row.orderStatusId !== 7 && row.orderStatusId !== 5 && row.orderStatusId !== 6) && <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => getAvailableSlots(row)}>Change Delivery Slot</Button>
                          }
                          {
                            row.orderStatusId === 1 &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Accept</Button>
                          }
                          {
                            (row.orderStatusId === 2 && row.orderTypeId === 1) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Ship</Button>
                          }
                          {
                            (row.orderStatusId === 2 && row.orderTypeId === 2) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Ready</Button>
                          }
                          {
                            (row.orderStatusId === 4 || row.orderStatusId === 5) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Deliver</Button>
                          }
                          {
                            row.orderStatusId === 1 &&
                            <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => openConfirmDialogForCancel(row)}>Cancel</Button>
                          }
                        </TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style' onClick={() => viewOrderDetail(row)}>View Order</Button>
                          <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => navToMessage(row.id)}>Direct</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {/* Tablet View */}
          <div className='tabview'>
            <div className='ml-0 mt-4'>
            </div>

            <div className='my-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Shop</TableCell>
                      <TableCell>Delivery Type</TableCell>
                      <TableCell>Delivery Date</TableCell>
                      <TableCell>Consumer</TableCell>
                      <TableCell className='text-right'>Order#</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell className='text-right'>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderList.map((row: any, i) => (
                      <TableRow>
                        <TableCell><FxDateDisplay value={row.placedDate} /></TableCell>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>
                          {row.orderTypeId == 1 &&
                            <span>Delivery</span>
                          }
                          {row.orderTypeId == 2 &&
                            <span>Pickup</span>
                          }
                        </TableCell>
                        <TableCell><FxDateDisplay value={row.orderDate} /></TableCell>
                        <TableCell>
                          <div className='row'>
                            <span>{row.consumerName}</span>
                            <span className='pl-2'>{row.consumerPhone}</span>
                          </div>
                        </TableCell>
                        <TableCell className='text-right'>#{row.orderIdentifier}</TableCell>
                        <TableCell>
                          {row.orderStatus}
                          {
                            row.orderStatus === 'Cancelled' &&
                            <span className='pl-2'>({row.cancelledRemarks})</span>
                          }
                        </TableCell>
                        <TableCell className='text-right'><FxCurrency value={row.orderTotal} /></TableCell>
                        <TableCell>
                          {row.orderPayments && row.orderPayments.map((itemObj, i) => (
                            <div>
                              {itemObj.paymentMode == 'cod' &&
                                <span className='payment-style'>
                                  Cash / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode == 'stripe' &&
                                <span className='payment-style'>
                                  {'Card :' + itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'stripe' &&
                                <span className='payment-style'>
                                  {itemObj.paymentMode} / {itemObj.paymentStatus}
                                </span>
                              }
                            </div>
                          ))
                          }
                        </TableCell>
                        <TableCell>
                          {
                            row.orderStatusId !== 7 && <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => getAvailableSlots(row)}>Change Delivery Slot</Button>
                          }
                          {
                            row.orderStatusId === 1 &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Accept</Button>
                          }
                          {
                            (row.orderStatusId === 2 && row.orderTypeId === 1) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Ship</Button>
                          }
                          {
                            (row.orderStatusId === 2 && row.orderTypeId === 2) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Ready</Button>
                          }
                          {
                            (row.orderStatusId === 4 || row.orderStatusId === 5) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Deliver</Button>
                          }
                          {
                            row.orderStatusId === 1 &&
                            <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => openConfirmDialogForCancel(row)}>Cancel</Button>
                          }
                        </TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style' onClick={() => viewOrderDetail(row)}>View Order</Button>
                          <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => navToMessage(row.id)}>Direct</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {/* Mobile View */}
          <div className='mobileview mb-4'>
            <div className='ml-0 mt-4 mb-4'>
            </div>
            {orderList.map((row: any, i) => (
              <div>
                <Card className="mt-3 py-2 px-3">
                  <div className='row justify-content-between px-2'>
                    <div>
                      <div className='font-13 bold-text'>#{row.orderIdentifier}</div>
                      <div className='font-11'><FxDateDisplay value={row.placedDate} /></div>
                    </div>
                    <div className='text-right'>
                      <div className='font-13 bold-text'><FxCurrency value={row.orderTotal} /></div>
                      <div className='font-11'>
                        {row.orderStatus}
                        {
                          row.orderStatus === 'Cancelled' &&
                          <span className='pl-2'>({row.cancelledRemarks})</span>
                        }
                      </div>
                    </div>
                  </div>
                  <div className='row justify-content-between px-2 pt-2'>
                    <div className='font-13'>{row.stallName}</div>
                    <div className='font-13 text-right'>
                      {row.orderTypeId == 1 &&
                        <span>Delivery</span>
                      }
                      {row.orderTypeId == 2 &&
                        <span>Pickup</span>
                      }
                    </div>
                  </div>
                  <div className='row justify-content-between px-2 pt-1'>
                    <div className='font-13'>
                      {row.consumerName}
                    </div>
                    <div className='font-13'>
                      {row.consumerPhone}
                    </div>
                  </div>
                  <div className='row justify-content-between px-2 pt-1'>
                    <div className='font-12'>
                      {row.orderPayments && row.orderPayments.map((itemObj, i) => (
                        <div>
                          {itemObj.paymentMode == 'cod' &&
                            <span className='payment-style'>
                              Cash / {itemObj.paymentStatus}
                            </span>
                          }
                          {itemObj.paymentMode == 'stripe' &&
                            <span className='payment-style'>
                              {'Card :' + itemObj.paymentStatus}
                            </span>
                          }
                          {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'stripe' &&
                            <span className='payment-style'>
                              {itemObj.paymentMode} / {itemObj.paymentStatus}
                            </span>
                          }
                        </div>
                      ))
                      }
                    </div>
                    <div className='text-right'>
                      {
                        row.orderStatusId !== 7 && <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => getAvailableSlots(row)}>Change Delivery Slot</Button>
                      }
                      {
                        row.orderStatusId === 1 &&
                        <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Accept</Button>
                      }
                      {
                        (row.orderStatusId === 2 && row.orderTypeId === 1) &&
                        <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Ship</Button>
                      }
                      {
                        (row.orderStatusId === 2 && row.orderTypeId === 2) &&
                        <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Ready</Button>
                      }
                      {
                        (row.orderStatusId === 4 || row.orderStatusId === 5) &&
                        <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Deliver</Button>
                      }
                      {
                        row.orderStatusId === 1 &&
                        <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => openConfirmDialogForCancel(row)}>Cancel</Button>
                      }
                    </div>
                    <div className='text-right'>
                      <Button variant='outlined' size='small' className='button-style' onClick={() => viewOrderDetail(row)}>View Order</Button>
                      <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => navToMessage(row.id)}>Direct</Button>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>

        </div>
      </div>
      <div>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ProviderItemOrderDetail orderId={orderId} handleClose={handleClose} />
        </Dialog>
      </div>
      <div>
        <Dialog
          open={confirmOpen}
          maxWidth="sm"
          fullWidth
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} action={isCancelAction ? "cancel" : ""} />
        </Dialog>
      </div>
      <div>
        <Dialog
          open={deliverySlotsOpen}
          maxWidth="md"
          fullWidth
          onClose={closedeliverySlots}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DeliverySlots
            handleClose={closedeliverySlots}
            availableSlots={availableSlots}
            deliverySlotId={deliverySlotId}
            onSelectSlot={onSelectSlot}
            isSave={true}
            saveDeliverySlot={changeDeliverySlot}
          />
        </Dialog>
        <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openMessage} onClose={toggleDrawerMessage}>
          <MessageContainer orderId={orderId} handleMessageClose={handleMessageClose} />
        </Drawer>
      </div>
    </div>
  );

  return (
    <div>{body}</div>
  );

}

export default ProviderItemOrders;