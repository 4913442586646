import React, { useEffect, useState } from 'react';
import './StallOrders.scss';
import { FxDateDisplay, FxCurrency, http, formatService, AlertService, general, useNavigationService } from '../../fx-core';
import * as _ from 'lodash';
import { Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ProviderItemOrderDetail from '../ProviderItemOrderDetail/ProviderItemOrderDetail';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { DeliverySlots } from '../../OrderConfirmation/DeliverySlots/DeliverySlots';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const StallOrders = (props: any) => {
  const { navigate } = useNavigationService();
  const initialList = [];
  const selOrderDefaultObj = {
    id: 0,
    orderStatusId: 0,
    orderTypeId: 0
  };
  const [stallId, setStallId] = useState(props.match.params.id ? parseInt(props.match.params.id) : 0);
  const [type, setType] = useState(props.match.params.type ? props.match.params.type : null);
  const [orderList, setOrderList] = useState(initialList);
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(selOrderDefaultObj);
  const [isCancelAction, setIsCancelAction] = useState(false);
  const [deliverySlotsOpen, setDeliverySlotsOpen] = useState(false);
  const [availableSlots, setAvailableSlots] = useState(initialList);
  const [deliverySlotId, setDeliverySlotId] = useState(0);

  const initMethod = () => {
    if (type == 'open') {
      getOpenOrders();
    }
    else if (type == 'shipped') {
      getShippedOrders();
    }
  }

  const getOpenOrders = () => {
    let inputData = {
      id: stallId
    }
    let apiOptions: any = {
      url: `order/pendingorders/stall/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      setOrderList(result);
    })
  };

  const getShippedOrders = () => {
    let inputData = {
      id: stallId
    }
    let apiOptions: any = {
      url: `order/shipped/stall/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      setOrderList(result);
    })
  };

  const viewOrderDetail = (item: any) => {
    setOpen(true);
    setOrderId(item.id);
  };

  const handleClose = () => {
    setOpen(false);
    setOrderId(0);
  };

  const defaulConfirmDialogStateValues = () => {
    setSelectedOrder(selOrderDefaultObj);
    setMessage(null);
    setConfirmOpen(false);
    if (isCancelAction) {
      setIsCancelAction(false);
    }
  };

  const openConfirmDialogByStatus = (item) => {
    let msg = "";
    if (item.orderStatusId === 1) {
      msg = "Do you want to accept the order #" + item.orderIdentifier + "?";
    }
    else if (item.orderStatusId === 2 && item.orderTypeId === 1) {
      msg = "Do you want to ship the order #" + item.orderIdentifier + "?";
    }
    else if (item.orderStatusId === 2 && item.orderTypeId === 2) {
      msg = "Is the order #" + item.orderIdentifier + " ready?";
    }
    else if (item.orderStatusId === 4 || item.orderStatusId === 5) {
      msg = "Do you want to deliver the order #" + item.orderIdentifier + "?";
    }
    setSelectedOrder(item);
    setMessage(msg);
    setConfirmOpen(true);
  };

  const handleDialogClose = () => {
    defaulConfirmDialogStateValues()
  };

  const onConfirmCallback = (action, comments) => {
    if (selectedOrder.orderStatusId === 1) {
      if (action === 'cancel') {
        cancelOrder(comments);
      } else {
        markAsAccepted();
      }
    }
    else if (selectedOrder.orderStatusId === 2) {
      markAsReadyOrShipped();
    }
    else if (selectedOrder.orderStatusId === 4 || selectedOrder.orderStatusId === 5) {
      markOrderDelivered();
    }
  };

  const markAsAccepted = () => {
    let apiOptions = {
      url: `order/${selectedOrder.id}/markAsAccepted`
    };
    http.put(apiOptions).then(res => {
      markAsAcceptedCallBack(res);
    });
  };

  const markAsAcceptedCallBack = (res) => {
    AlertService.showMsg({ message: "Order is accepted" });
    defaulConfirmDialogStateValues();
    initMethod();
  };

  const markAsReadyOrShipped = () => {
    let apiOptions = {
      url: `order/${selectedOrder.id}/MarkAsReadyOrShipped`
    };
    http.put(apiOptions).then(res => {
      markAsReadyOrShippedCallBack(res);
    });
  };

  const markAsReadyOrShippedCallBack = (res) => {
    let successMsg = "";
    if (selectedOrder.orderTypeId === 1) {
      successMsg = "Order shipped";
    }
    else if (selectedOrder.orderTypeId === 2) {
      successMsg = "Order ready";
    }
    AlertService.showMsg({ message: successMsg });
    defaulConfirmDialogStateValues();
    initMethod();
  };

  const markOrderDelivered = () => {
    let inputData = {
      id: selectedOrder.id,
      currentDate: formatService.getCurrentDateForServer()
    }
    let apiOptions = {
      url: `order/MarkOrderDelivered`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      markOrderDeliveredCallBack(res);
    });
  };

  const markOrderDeliveredCallBack = (res) => {
    AlertService.showMsg({ message: "Order delivered" });
    defaulConfirmDialogStateValues();
    initMethod();
  };

  const openConfirmDialogForCancel = (item) => {
    setSelectedOrder(item);
    setIsCancelAction(true);
    setConfirmOpen(true);
  };

  const cancelOrder = (comments) => {
    let inputData = {
      id: selectedOrder.id,
      cancelledRemarks: comments
    }
    let apiOptions = {
      url: `order/MarkOrderCancelled`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      cancelOrderCallBack(res);
    });
  };

  const cancelOrderCallBack = (res) => {
    defaulConfirmDialogStateValues();
    initMethod();
  };

  const closedeliverySlots = () => {
    setDeliverySlotsOpen(false);
    setOrderId(0);
    setDeliverySlotId(0);
  };

  const getAvailableSlots = (item) => {
    setOrderId(item.id);
    setDeliverySlotId(item.deliverySlotId);
    let inputData = {
      stallId: item.stallId
    }
    let apiOptions = {
      url: `stalldeliveryslot/available`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getAvailableSlotsCallBack(res);
    });
  };

  const getAvailableSlotsCallBack = (res) => {
    const result = res.data;
    if (result.deliverySlots.length > 0) {
      for (let item of result.deliverySlots) {
        item.startTime = item.startTime != null ? general.getFormattedTime(item.startTime) : null;
        item.endTime = item.endTime != null ? general.getFormattedTime(item.endTime) : null;
      }
      setAvailableSlots(result.deliverySlots);
    }
    else {
      setAvailableSlots([]);
    }
    setDeliverySlotsOpen(true);
  };

  const onSelectSlot = (item) => {
    if (item.id != deliverySlotId) {
      setDeliverySlotId(item.id);
    }
    else {
      setDeliverySlotId(0);
    }
  };

  const changeDeliverySlot = () => {
    let inputData = {
      id: orderId,
      deliverySlotId: deliverySlotId
    };
    let apiOptions = {
      url: `order/updatedeliveryslot`,
      data: inputData
    };
    http.put(apiOptions).then(res => {
      changeDeliverySlotCallBack(res);
    });
  };

  const changeDeliverySlotCallBack = (res) => {
    AlertService.showMsg({ message: "Updated delivery slot" });
    closedeliverySlots();
    initMethod();
  };

  const navToDashboard = () => {
    let navData = {
      targetRoute: '/provider/providerdashboard',
      targetTitle: "Provider Dashboard",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }


  useEffect(() => {
    initMethod();
  }, [])


  const body = (
    <div className="stallorders-ui pt-5">
      <div className="content-container pt-4">
        <div className="content">
          <div className='pt-2'>
            <div className="title-font custom-head pl-4">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToDashboard}>
                  <b>Provider Dashboard</b>
                </Link>
                <Typography color="primary" className="breadcrumbs-style">
                  {type == 'open' &&
                    <span>Open Orders</span>
                  }
                  {type == 'shipped' &&
                    <span>Delivery Orders</span>
                  }
                </Typography>
              </Breadcrumbs>
            </div>
            <div className='my-4 px-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Shop</TableCell>
                      <TableCell>Delivery Type</TableCell>
                      <TableCell>Consumer</TableCell>
                      <TableCell className='text-right'>Order#</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell className='text-right'>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderList.map((row, i) => (
                      <TableRow>
                        <TableCell><FxDateDisplay value={row.palcedDate} /></TableCell>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>
                          {row.orderTypeId == 1 &&
                            <span>Delivery</span>
                          }
                          {row.orderTypeId == 2 &&
                            <span>Pickup</span>
                          }
                        </TableCell>
                        <TableCell>
                          <div className='row'>
                            <span>{row.consumerName}</span>
                            <span className='pl-2'>{row.consumerPhone}</span>
                          </div>
                        </TableCell>
                        <TableCell className='text-right'>#{row.orderIdentifier}</TableCell>
                        <TableCell>
                          {row.orderStatus}
                          {
                            row.orderStatus === 'Cancelled' &&
                            <span className='pl-2'>({row.cancelledRemarks})</span>
                          }
                        </TableCell>
                        <TableCell className='text-right'><FxCurrency value={row.orderTotal} /></TableCell>
                        <TableCell>
                          {row.orderPayments && row.orderPayments.map((itemObj, i) => (
                            <div>
                              {itemObj.paymentMode == 'cod' &&
                                <span className='payment-style'>
                                  Cash / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode == 'stripe' &&
                                <span className='payment-style'>
                                  {'Card :' + itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'stripe' &&
                                <span className='payment-style'>
                                  {itemObj.paymentMode} / {itemObj.paymentStatus}
                                </span>
                              }
                            </div>
                          ))
                          }
                        </TableCell>
                        <TableCell>
                          {
                            row.orderStatusId !== 7 && <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => getAvailableSlots(row)}>Change Delivery Slot</Button>
                          }
                          {
                            row.orderStatusId === 1 &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Accept</Button>
                          }
                          {
                            (row.orderStatusId === 2 && row.orderTypeId === 1) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Ship</Button>
                          }
                          {
                            (row.orderStatusId === 2 && row.orderTypeId === 2) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Ready</Button>
                          }
                          {
                            (row.orderStatusId === 4 || row.orderStatusId === 5) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Deliver</Button>
                          }
                          {
                            row.orderStatusId === 2 &&
                            <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => openConfirmDialogForCancel(row)}>Cancel</Button>
                          }
                        </TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style' onClick={() => viewOrderDetail(row)}>View Order</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="text-center m-2">
              {orderList && orderList.length == 0 &&
                <span className='font-18'><b>No Stall Orders</b></span>
              }
            </div>
          </div>
        </div>
      </div>
      <div>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ProviderItemOrderDetail orderId={orderId} handleClose={handleClose} />
        </Dialog>
      </div>
      <div>
        <Dialog
          open={confirmOpen}
          maxWidth="sm"
          fullWidth
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} action={isCancelAction ? "cancel" : ""} />
        </Dialog>
      </div>
      <div>
        <Dialog
          open={deliverySlotsOpen}
          maxWidth="md"
          fullWidth
          onClose={closedeliverySlots}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DeliverySlots
            handleClose={closedeliverySlots}
            availableSlots={availableSlots}
            deliverySlotId={deliverySlotId}
            onSelectSlot={onSelectSlot}
            isSave={true}
            saveDeliverySlot={changeDeliverySlot}
          />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>{body}</div>
  );

}

export default StallOrders;