import React, { useState, useEffect } from 'react';
import './RegisterComponent.scss';
import Button from '@material-ui/core/Button';
import { FxInput, http, AlertService, localstorage, FxMobile, formatService, session, formValidator } from './../fx-core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import AppStore from '../../assets/AppStore.png';
import GooglePlay from '../../assets/GooglePlay.png';
import FacebookLogin from 'react-facebook-login';
import { Constants } from '../utils/Constants';
import { Link } from '@material-ui/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const RegisterComponent = (props: any) => {
  const defaultReferralCode = session.getItem('referralCode');
  const defaultItem = {
    firstName: "",
    email: "",
    mobile: "",
    id: 0,
    userName: "",
    password: "",
    accept: false,
    defaultCommunityId: parseInt(localstorage.getItem("communityId")),
    referralCode: defaultReferralCode,
    countryCode: ''
  };

  const clientId = Constants.GOOGLE_CLIENT_ID;
  const appId = Constants.FB_APP_ID;
  const [item, setItem] = useState(defaultItem);
  const [errors, setErrors] = useState({});
  const [qrcode, setQrcode] = useState(null);
  const [link, setLink] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [stallId, setStallId] = useState(props.stallId ? props.stallId : 0);
  const [stallQrcode, setStallQrcode] = useState(null);
  const [countryCode, setCountryCode] = useState([]);
  const [showPassword,setShowPassword] = useState(false);

 const handleClickShowPassword= ()=> setShowPassword(!showPassword);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
  };

  const handleCheckbox = (event) => {
    handleItemChange({ [event.target.name]: event.target.checked });
  };

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        CountryCode: { default: false }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setCountryCode(response.data.CountryCode);
    })
  }

  const validationMap = {
    firstName: ["required"],
    userName: ["required"],
    password: ["required"],
    email: ["required", "email"],
    mobile: ["required", "mobileno"]
  };


  const getInputValidations = (name) => {
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items = [];
    for (var key in validationMap) {
      let itemVal = { name: key, value: item[key], validations: validationMap[key] }
      items.push(itemVal);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };

  const saveRegister = () => {
    const isValid = bulkValidate();
    if (isValid) {
      if (!item.accept) {
        AlertService.showErrorMsg("Please accept terms&conditions");
        return;
      }
      if (item.mobile) {
        item.mobile = item.countryCode + item.mobile;
      }
      let inputData = {
        firstName: item.firstName,
        email: item.email,
        mobile: item.mobile,
        userName: item.userName,
        password: item.password,
        defaultCommunityId: item.defaultCommunityId,
        referralCode: item.referralCode,
        stallId: stallId,
        currentDate: formatService.getCurrentDateForServer()
      };
      let apiOptions: any = {
        url: `user/portal/register`,
        data: inputData
      };
      http.post(apiOptions).then(res => {
        saveRegisterCallBack(res);
      });
    }
  };

  const saveRegisterCallBack = (res) => {
    AlertService.showSuccessMsg();
    /*setLink(res.data.linkUrl);
    getQrCodeByLink(res.data.linkUrl);
    session.setItem('referralCode', "");*/
    navigateBack();
  };

  const getQrCodeByLink = (link: any) => {
    setCurrentStep(2);
    let inputData: any = {
      linkUrl: link
    };

    let apiOptions: any = {
      url: `user/portal/register/qrcode`,
      data: inputData,
      method: 'post'
    };

    apiOptions.callback = viewImage;
    http.view(apiOptions)
  };

  const viewImage = (res: any) => {
    let qrCode = res.base64data;
    setQrcode(qrCode);
  }

  const navigateBack = () => {
    props.handleRegisterCallBack();
  }

  const onGoogleLoginSuccess = (userData) => {
    let inputData = {
      email: userData.profileObj.email,
      communityId: item.defaultCommunityId,
      displayName: userData.profileObj.name,
      userId: userData.profileObj.googleId,
      externalProvider: "google",
      externalData: userData.profileObj,
      stallId: stallId
    }
    let apiOptions: any = {
      url: `security/registerconsumerportal`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      saveRegisterCallBack(res);
    });
  }

  const onFailure = (response: any) => {
    console.log(response);
  }

  const onFacebookLoginSuccess = (userData) => {
    let inputData = {
      email: userData.profileObj.email,
      communityId: item.defaultCommunityId,
      name: userData.profileObj.name,
      externalUserId: userData.profileObj.userId,
      externalProvider: "facebook",
      externalData: userData.profileObj,
      stallId: stallId
    }
    let apiOptions: any = {
      url: `security/registerconsumerportal`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      saveRegisterCallBack(res);
    });
  }

  //Get qr code and mail link
  // const getQrCodeAndLink = (userId) => {
  //     setCurrentStep(2);
  //     getLinkUrl(userId);
  // }

  // const getLinkUrl = (userId) => {
  //     let inputData = {
  //         userId: userId,
  //         stallId: stallId
  //     }
  //     let apiOptions: any = {
  //         url: `user/portal/register/maillink`,
  //         data: inputData
  //     };
  //     http.post(apiOptions).then(response => {
  //         let result = response.data;
  //         setLink(result.linkUrl);
  //         getQrCodeByLink(result.linkUrl);

  //     });
  // }

  const getStallQrCode = () => {
    let apiOptions: any = {
      url: `portal/stall/${stallId}/qrcode`,
      method: 'get'
    };

    apiOptions.callback = viewStallImage;
    http.view(apiOptions)
  };

  const viewStallImage = (res: any) => {
    let qrCode = res.base64data;
    setStallQrcode(qrCode);
  }


  const openGooglePlaystore = (url) => {
    window.open('https://play.google.com/store/apps/details?id=app.commkit', '_blank');
  }
  const openAppStore = (url) => {
    window.open('https://apps.apple.com/nz/app/commkit/id1522642980', '_blank');
  }

  const openLinkUrl = () => {
    window.open(link, '_blank');
  }

  const openTermsAndConditions = () => {
    window.open("https://www.localites.in/terms-of-service", '_blank');
  }

  useEffect(() => {
    initLookup();
    if (stallId > 0)
      getStallQrCode();
  }, []);

  return (
    <div className="registercomponent-ui mt-5 mb-4">
      <div>
        {currentStep == 1 &&
          <span>
            <div className='app-download'>
              <div className="text-center font-17">
                Already an existing user?
              </div>
              <div className="text-center font-17">
                Please Sign In to <b>Localites Mobile app</b> to continue
              </div>
              {
                stallQrcode &&
                <div className="row justify-content-center pt-3">
                  <div className="scan-text">Scan QRCODE to buy items</div>
                  <div className="p-2 text-center">
                    <img src={stallQrcode} alt="Stall Qrcode Image" width="30%" />
                  </div>
                </div>
              }
              <div className="divider-line py-4"></div>
              <div className="text-center pb-3 font-17">
                <b>New User</b>
                {/* <div className="heading-line"></div> */}
              </div>
              <div className="text-center pb-3 font-17">
                Please Signup to continue
              </div>
            </div>


            <div className="text-center">
              {/* <GoogleLogin
                                clientId={clientId}
                                buttonText="Sign Up Using Google"
                                onSuccess={onGoogleLoginSuccess}
                                onFailure={onFailure}
                                cookiePolicy={'single_host_origin'}
                            /> */}
            </div>
            <div>
              {/* <FacebookLogin
                                appId={appId}
                                autoLoad={true}
                                fields="name,email,picture"
                                callback={onFacebookLoginSuccess}
                                cssClass="my-facebook-button-class"
                                icon="fa-facebook"
                            /> */}
            </div>
            {/* <div className="py-4 text-center">
                            Or
                        </div> */}
            <div className="m-1">
              <FxInput name="firstName" variant="outlined" label="Name" fullWidth
                size="small" value={item.firstName} onValueChange={handleInputChange}
              />
              {
                hasError("firstName", "required") &&
                <div className="validation-msg">Field is required</div>
              }
            </div>
            <div className="pt-4 m-1">
              <FxInput name="email" variant="outlined" label="Email"
                fullWidth size="small" value={item.email} onValueChange={handleInputChange}
              />
              {
                hasError("email", "required") &&
                <div className="validation-msg">Field is required</div>
              }
              {
                hasError("email", "email") &&
                <div className="validation-msg">Please enter valid email id</div>
              }
            </div>
            <div className="pt-4 m-1">
              <FxMobile value={item.mobile} onValueChange={handleInputChange} countryCode={countryCode} />

              {hasError("mobile", "required") && (
                <div className="validation-msg">Field is required</div>
              )}
              {
                hasError("mobile", "mobileno") &&
                <div className="validation-msg">Please enter valid mobile number</div>
              }
            </div>
            <div className="pt-4 m-1">
              <FxInput name="userName" variant="outlined" label="Username"
                fullWidth size="small" value={item.userName} onValueChange={handleInputChange}
              />
              {
                hasError("userName", "required") &&
                <div className="validation-msg">Field is required</div>
              }
            </div>
            <div className="pt-4 m-1">
              <FxInput name="password" type={showPassword ? "text" : "password"} variant="outlined" label="Password"
                fullWidth size="small" value={item.password} onValueChange={handleInputChange}
                endAdornment={showPassword ? <VisibilityOff sx={{ cursor: "pointer", color: "#555" }} onClick= {handleClickShowPassword}/> : <Visibility sx={{ cursor: "pointer", color: "#555" }} onClick= {handleClickShowPassword}/>
                }
              />
              {
                hasError("password", "required") &&
                <div className="validation-msg">Field is required</div>
              }
            </div>
            {/* <div className="pt-4">
                    <FxInput name="community" variant="outlined" label="Community"
                        fullWidth
                        size="small"
                    />
                </div> */}
            <div className="pt-4 m-1">
              <FxInput name="referralCode" variant="outlined" label="Referral Code"
                fullWidth size="small" value={item.referralCode} onValueChange={handleInputChange}
              />
            </div>
            <div className="pt-3 m-1">
              <Checkbox name="accept" color="primary" value={item.accept} onChange={handleCheckbox} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
              <span>I Accept </span>
              <span className='color-primary pointer' onClick={openTermsAndConditions}><u>Terms and Conditions</u></span>
            </div>
            <div className="pt-4 m-1">
              <Button variant="contained" size="medium" color="primary" fullWidth style={{ color: '#FFFFFF', fontWeight: 600 }}
                onClick={saveRegister}>
                Sign Up
              </Button>
            </div>
            {props.fromLanding &&
              <div className="m-2 font-16 text-center py-3">
                <Link onClick={navigateBack} className="font-weight-bold pointer">Back</Link>
              </div>
            }
          </span>
        }
        {currentStep == 2 &&
          <span className="text-center">
            <div className="scan-text">Scan QRCODE</div>
            <div className="p-2">
              <img src={qrcode} alt="Qrcode Image" width="50%" />
            </div>
            <div className="p-2">or</div>
            <div className="p-2">Open the Link below in mobile to login into the app</div>
            <div className="p-2 pb-4 color-primary pointer" onClick={openLinkUrl}><u><b>App Download Link</b></u></div>

            <div className="row mt-4 pb-3 pr-3 justify-content-end">
              <span className="pl-2">
                <Button variant="contained" size="medium" onClick={navigateBack}>Back</Button>
              </span>
            </div>
          </span>
        }
      </div>
    </div>
  );
}
export default RegisterComponent;