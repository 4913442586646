import React, { useEffect, useState } from 'react';
import '../Home.scss';
import './Footer.scss';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import AppStore from '../../../assets/AppStore.png';
import GooglePlay from '../../../assets/GooglePlay.png';
import facebook from "../images/Icon/facebook.png";
import twitter from "../../../assets/images/twitter.png";
import instagram from "../images/Icon/instagram.png";
import fb from "../../../assets/images/facebook.png";
import linkedin from "../../../assets/images/linkedin.png";
import youtube from "../../../assets/images/youtube.png";
import logo from '../../../assets/img/localiteslogo1.png';
import CopyrightIcon from '@material-ui/icons/Copyright';
import { formValidator, FxInput, FxMobile, FxRadioGroup, http, useNavigationService } from '../../fx-core';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 'auto',
      bottom: 0,
      backgroundColor: '#F1F1F1',
      color: '#000000',
    },
    textField: {
      backgroundColor: '#D6EED8',
      color: 'primary',
    },
  }),
);


const Footer = (props: any) => {
  const classes = useStyles();
  const { navigate } = useNavigationService();
  const login = useSelector<any, any>(s => s.login);

  let defaultFormModel = {
    name: "",
    mobile: "",
    email: "",
    interestedIn: "vendor",
    captcha: ""
  };
  const [formModel, setFormModel] = useState(defaultFormModel);
  const [errors, setErrors] = useState({});
  const [countryCode, setCountryCode] = useState([]);

  const handleInputChange = (item) => {
    handleFormModelChange({ [item.name]: item.value });
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
  };

  const handleFormModelChange = (data) => {
    setFormModel(prevState => {
      return { ...prevState, ...data }
    });
  };

  const validationMap_Item = {
    name: ["required"],
    mobile: ["required", "mobileno"],
    email: ["required"],
    interestedIn: ["required"],
    captcha: ["required"]
  };

  const getValidations = () => {
    let validationMap;
    validationMap = validationMap_Item;
    return validationMap;
  }

  const getInputValidations = (name) => {
    let validationMap = getValidations();
    return validationMap[name];
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        CountryCode: { default: false }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setCountryCode(response.data.CountryCode);
    })
  }

  const openCommunity = (url) => {
    //window.open('https://community.commkit.co.nz/home', '_blank');
  }
  const openFaceBook = (url) => {
    window.open('https://www.facebook.com/localites.in/', '_blank');
  }
  const openTwitter = (url) => {
    window.open('https://x.com/localites_in', '_blank');
  }
  const openInstagram = (url) => {
    window.open('https://www.instagram.com/localites_in', '_blank');
  }
  const openLinkedin = (url) => {
    window.open('https://www.linkedin.com/company/localites-in', '_blank');
  }
  const openYoutube = (url) => {
    window.open('https://www.youtube.com/@Localites', '_blank');
  }
  const openGooglePlaystore = (url) => {
    // window.open('https://play.google.com/store/apps/details?id=app.commkit', '_blank');
  }
  const openAppStore = (url) => {
    // window.open('https://apps.apple.com/nz/app/commkit/id1522642980', '_blank');
  }

  const openPolicy = (item: any) => {
    window.open(item.policyLink, '_blank');
  }

  const navToContactUs = () => {
    let navData = {
      targetRoute: '/contact',
      targetTitle: "Contact Us",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }


  const policies = [{ policyName: "About Us", policyLink: "https://www.localites.in/about" },
  { policyName: "Contact Us", policyLink: "" },
  { policyName: "Pricing Plans", policyLink: "https://www.localites.in/pricing" },
  { policyName: "Privacy Policy", policyLink: "https://www.localites.in/privacy" },
  { policyName: "Terms & Conditions", policyLink: "https://www.localites.in/terms-of-service" },
  { policyName: "Cancellation & Refund", policyLink: "https://www.localites.in/refund" }
  ]

  const types = [{ code: "vendor", text: "Vendor/Provider" }, { code: "partner", text: "Partner Program" }]

  useEffect(() => {
    initLookup();
  }, []);

  return (<>

    <Box width="100%" className="policy-color">
      <Container fixed disableGutters>
        <div className="mx-auto footer-ui">
          <div className='deskview'>
            <div className='d-flex justify-content-center py-4' >
              {policies.length && policies.map((item: any, i: any) => (
                <>
                  {login.isLogin && item.policyName == "Contact Us" && <>
                    <span className='pointer color-white' onClick={navToContactUs} key={i}>
                      {item.policyName}
                    </span>
                    {i < policies.length - 1 &&
                      <span className='px-2 color-primary'>|</span>
                    }
                  </>
                  }
                  {item.policyName != "Contact Us" && <>
                    <span className='pointer color-white' onClick={() => openPolicy(item)} key={i}>
                      {item.policyName}
                    </span>
                    {i < policies.length - 1 &&
                      <span className='px-2 color-primary'>|</span>
                    }
                  </>
                  }

                </>
              ))}
            </div>
          </div>

          <div className='mobileview'>
            <div className='row pl-3 pt-2 pb-4' >
              {policies.length && policies.map((item: any, i: any) => (
                <>
                  {login.isLogin && item.policyName == "Contact Us" && <>
                    <span className='col-6 pointer mt-2 color-white' onClick={navToContactUs} key={i}>
                      {item.policyName}
                    </span>
                  </>
                  }
                  {item.policyName != "Contact Us" && <>
                    <span className='col-6 pointer mt-2 color-white' onClick={() => openPolicy(item)} key={i}>
                      {item.policyName}
                    </span>
                  </>
                  }
                </>

              ))}
            </div>
          </div>
        </div>
      </Container>
    </Box>

    <Box width="100%" className="footer-color">
      <Container fixed disableGutters>
        <div className="mx-auto footer-ui pt-4">
          <div className='row'>
            <span className='col-sm-12 col-md-4 mb-3'>
              <div className="font-20 logodesign pointer">
                <span className="d-flex align-items-center justify-content-center">
                  <img src={logo} alt="logo" width="200"></img>
                </span>
              </div>
            </span>
            {/* <span className='col-sm-12 col-md-4 mb-3 px-4'>
            <div className='font-weight-bold text-center'>Contact Us</div>
              <div className="m-1 pt-3">
                <FxInput name="name" variant="outlined" label="Name" fullWidth
                  value={formModel.name} onValueChange={handleInputChange} />
                {hasError("name", "required") && (
                  <div className="error">Field is required</div>
                )}
              </div>
              <div className="m-1 pt-3">
                <FxMobile value={formModel.mobile} onValueChange={handleInputChange} countryCode={countryCode} />

                {hasError("mobile", "required") && (
                  <div className="error">Field is required</div>
                )}
                {
                  hasError("mobile", "mobileno") &&
                  <div className="error">Mobile should contain only numbers</div>
                }
              </div>
              <div className="m-1 pt-3">
                <FxInput name="email" variant="outlined" label="Email" fullWidth
                  value={formModel.email} onValueChange={handleInputChange} />
                {hasError("email", "required") && (
                  <div className="error">Field is required</div>
                )}
              </div>
              <div className='pt-3 m-1'>
                Intersted in
              </div>
              <FxRadioGroup
                name="interestedIn"
                options={types}
                valueField="code"
                displayField="text"
              />
              <div className="m-1 pt-3 text-center">
                <Button variant="contained" size="medium" color="primary">
                  Submit
                </Button></div>
            </span> */}
            <span className='col-sm-12 col-md-4 mb-3'>
              <div className='font-weight-bold pl-3'>Contact</div>
              <div className='d-flex align-items-center pt-2 pl-3'>
                <span className='color-primary pr-2'><i className="fas fa-phone-alt"></i></span>
                <span>09626 777 360</span>
              </div>
              <div className='d-flex align-items-center pt-2 pl-3'>
                <span className='color-primary pr-2'><i className="fas fa-envelope"></i></span>
                <span>hello@localites.in</span>
              </div>
              <div className='d-flex pt-2 pb-2 pl-3'>
                <span className='color-primary pr-2'><i className="fas fa-map-marker-alt"></i></span>
                <span>
                  <div>No. 9, 1st Floor, Doctor's Colony,</div>
                  <div>Dr. Radhakrishna Street,</div>
                  <div>Gandhipuram, Coimbatore - 641 012</div>
                </span>
              </div>
            </span>
            <span className='col-sm-12 col-md-4 mb-3'>
              <div className="text-center">
                {/* <span>Join us - Explore, Engage &amp; Experience the new ways of working at <span className="color-primary font-weight-bold pointer" onClick={openCommunity}> Localites Community</span></span> */}
                <span>For any queries or issues email <a href='mailto:admin@localites.in'>admin@localites.in</a></span>
              </div>

              {/* <div className="row pt-3 pl-5 justify-content-center">
                        <div className="pr-3">
                            <img src={GooglePlay} alt="Google Play" width="130" onClick={openGooglePlaystore} className="pointer"></img>
                        </div>
                        <div className="pl-3">
                            <img src={AppStore} alt="App Store" width="130" onClick={openAppStore} className="pointer"></img>
                        </div>
                    </div> */}
              <div className="mobileview">
                <div className="pt-2 text-center">For better experience on Mobile</div>
                <div className="d-flex pt-2 justify-content-center">
                  <div className="pr-3">
                    <img src={GooglePlay} alt="Google Play" width="130" onClick={openGooglePlaystore} className="pointer"></img>
                  </div>
                  <div className="pl-3">
                    <img src={AppStore} alt="App Store" width="130" onClick={openAppStore} className="pointer"></img>
                  </div>
                </div>
              </div>
              <div className="d-flex py-3 justify-content-center">
                <span>
                  <img src={fb} alt="Facebook" width="37" className="pointer" onClick={openFaceBook} />
                </span>
                <span className="pl-4">
                  <img src={twitter} alt="Twitter" width="37" className="pointer" onClick={openTwitter} />
                </span>
                <span className="pl-4">
                  <img src={instagram} alt="Twitter" width="37" className="pointer" onClick={openInstagram} />
                </span>
                <span className="pl-4">
                  <img src={linkedin} alt="Twitter" width="37" className="pointer" onClick={openLinkedin} />
                </span>
                <span className="pl-4">
                  <img src={youtube} alt="Twitter" width="37" className="pointer" onClick={openYoutube} />
                </span>
              </div>
            </span>
          </div>



          {/* <div className="row col-12 py-4 justify-content-between">
                    <div className="col-1 font-11">
                            <div><b>Menu</b></div>
                            <div className="pt-2 pointer">Dashboard</div>
                            <div className="pt-1 pointer">My Orders</div>
                            <div className="pt-1 pointer">Referrals</div>
                            <div className="pt-1 pointer">My Bookings</div>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-1 font-11">
                            <div><b>Food</b></div>
                            <div className="pt-2 pointer">Ready to Eat</div>
                            <div className="pt-1 pointer">Food Bag</div>
                            <div className="pt-1 pointer">Fresh Produce</div>                            
                        </div>
                        <div className="col-1"></div>
                        <div className="col-1 px-0 font-11">
                            <div><b>Sale</b></div>
                            <div className="pt-2 pointer">Garage Sale</div>
                            <div className="pt-1 pointer">Creative Craft</div>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-1 px-0 font-11">
                            <div><b>Services</b></div>
                            <div className="pt-2 pointer">Child Care</div>
                            <div className="pt-1 pointer">Garden</div>
                            <div className="pt-1 pointer">Pet</div>
                            <div className="pt-1 pointer">Professional</div>
                        </div>
                        
                        
                        
                    </div> */}

          <div className="line"></div>
          <div className="col-12 py-3 text-center font-11">
            <span>Copyright <span><CopyrightIcon fontSize="inherit" /> </span> 2024 by <span className="primary-color"><b>AOTEAROA Solutions Pvt Limited</b></span>.  All Rights Reserved</span>
          </div>
        </div>
      </Container>
    </Box>
  </>);

}
export default Footer;