import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MuiThemeProvider, createTheme, Divider } from '@material-ui/core'
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { ProgressProvider } from './components/fx-core';
import Header from './components/home/Header/Header';
import Footer from './components/home/Footer/Footer';
import CustomerDashboard from './components/CustomerDashboard/CustomerDashboard';
import StallHome from './components/StallHome/StallHome';
import Stall from './components/Stall/Stall';
import ComingSoon from './components/ComingSoon/ComingSoon';
import LandingPage from './components/LandingPage/LandingPage';
import ListingServiceHome from './components/ListingServiceHome/ListingServiceHome';
import ChildCareListingHome from './components/ChildCareListingHome/ChildCareListingHome';
import ReviewForm from './components/ReviewForm/ReviewForm';
import MyAccountContainer from './components/MyAccount/MyAccountContainer/MyAccountContainer';
import LocalDrCard from './components/LocalDrCard/LocalDrCard';
import OrderConfirmationContainer from './components/OrderConfirmationContainer/OrderConfirmationContainer';
import OrderSummary from './components/OrderSummary/OrderSummary';
import CommunityPosts from './components/CommunityPosts/CommunityPosts';
import ListingOrderSummary from './components/ListingOrderSummary/ListingOrderSummary';
import ListingSale from './components/ListingSale/ListingSale';
import ProviderOrderContainer from './components/Provider/ProviderOrderContainer/ProviderOrderContainer';
import CreateServiceRequestContainer from './components/Provider/CreateServiceRequestContainer/CreateServiceRequestContainer';
import ProviderServices from './components/Provider/ProviderServices/ProviderServices';
import MyStallList from './components/Provider/MyStallList/MyStallList';
import ManageItems from './components/Provider/ManageItems/ManageItems';
import ProviderServiceRequests from './components/Provider/ProviderServiceRequests/ProviderServiceRequests';
import StallItemBatchList from './components/Provider/StallItemBatchList/StallItemBatchList';
import StallItemCustomize from './components/Provider/StallItemCustomize/StallItemCustomize';
import ProviderDashboard from './components/Provider/ProviderDashboard/ProviderDashboard';
import StallOrders from './components/Provider/StallOrders/StallOrders';
import ManageListings from './components/Provider/ManageListings/ManageListings';
import SaleItemForm from './components/Provider/SaleItemForm/SaleItemForm';
import DashboardSaleOrders from './components/Provider/DashboardSaleOrders/DashboardSaleOrders';
import StallListings from './components/Provider/StallListings/StallListings';
import LiveStreamList from './components/Provider/LiveStreamList/LiveStreamList';
import LiveStreamDetail from './components/LiveStreamDetail/LiveStreamDetail';
import ProviderList from './components/Community/ProviderList/ProviderList';
import LiveStreamLanding from './components/LiveStreamLanding/LiveStreamLanding';
import FollowedUsers from './components/Provider/FollowedUsers/FollowedUsers';
import Subscriptions from './components/Provider/Subscriptions/Subscriptions';
import CommunityList from './components/Superadmin/CommunityList/CommunityList';
import RegionDashboard from './components/RegionalManager/RegionDashboard/RegionDashboard';
import RegionCommunityList from './components/RegionalManager/RegionCommunityList/RegionCommunityList';
import ZoneDashboard from './components/ZonalManager/ZoneDashboard/ZoneDashboard';
import ZonalCommunityList from './components/ZonalManager/ZonalCommunityList/ZonalCommunityList';
import ProviderAccountList from './components/Provider/ProviderAccountList/ProviderAccountList';
import FeatureCouponList from './components/Provider/FeatureCouponList/FeatureCouponList';
import PendingPaymentList from './components/Community/PendingPaymentList/PendingPaymentList';
import SpecialEvents from './components/SpecialEvents/SpecialEvents';
import CommunitySelection from './components/CommunitySelection/CommunitySelection';
import ProviderLocalDrList from './components/Provider/ProviderLocalDrList/ProviderLocalDrList';
import MyPayments from './components/Provider/MyPayments/MyPayments';
import CommunityAdminDashboard from './components/CommunityAdmin/CommunityAdminDashboard/CommunityAdminDashboard';
import ServiceRequestList from './components/Community/ServiceRequestList/ServiceRequestList';
import UserList from './components/Community/UserList/UserList';
import StallList from './components/Community/StallList/StallList';
import CommunityReviews from './components/Community/CommunityReviews/CommunityReviews';
import CommunityItemOrders from './components/Community/CommunityItemOrders/CommunityItemOrders';
import ItemList from './components/Community/ItemList/ItemList';
import CategoryList from './components/Community/CategoryList/CategoryList';
import TrueFansBoard from './components/Community/TrueFansBoard/TrueFansBoard';
import CommunityProfile from './components/Community/CommunityProfile/CommunityProfile';
import ItemTagList from './components/Community/ItemTagList/ItemTagList';
import PaymentsContainer from './components/Community/PaymentsContainer/PaymentsContainer';
import CommunityMonthlyIncome from './components/Community/CommunityMonthlyIncome/CommunityMonthlyIncome';
import ManagersTrueFansBoard from './components/Community/ManagersTrueFansBoard/ManagersTrueFansBoard';
import ManagersReviews from './components/Community/ManagersReviews/ManagersReviews';
import SpecialCommunityList from './components/Community/SpecialCommunityList/SpecialCommunityList';
import SpecialEventList from './components/Provider/SpecialEventList/SpecialEventList';
import SpecialCommunityPayments from './components/Community/SpecialCommunityPayments/SpecialCommunityPayments';
import ListingStalls from './components/ListingServiceHome/ListingStalls/ListingStalls';
import CommunityServices from './components/Community/CommunityServices/CommunityServices';
import ServiceList from './components/Superadmin/ServiceList/ServiceList';
import ManageBookings from './components/Provider/ManageBookings/ManageBookings';
import BookingServiceHome from './components/BookingServiceHome/BookingServiceHome';
import StallBookings from './components/Provider/StallBookings/StallBookings';
import AccountDeletion from './components/AccountDeletion/AccountDeletion';
import ScrollToTop from './components/ScrollToTop/scrolltotop';
import ContactUs from './components/ContactUs/ContactUs';

const theme = createTheme({
  palette: {
    primary: {
      main: "#f69516", contrastText: "#ffffff"
    },
    secondary: {
      main: "#ffffff", contrastText: "#f69516"
    }
  },
  typography: {
    fontFamily: "sans-serif",
  },
});

export const App = () => {
  const hasLogin = window.location.pathname !== "/";
  console.log('window.location.pathname=', window.location.pathname);

  return (
    <MuiThemeProvider theme={theme}>
      <ProgressProvider>
        <Router>
          <ScrollToTop/>
          <div className="overflow-ui">
            <div className="fixed-top">
              <Header />
            </div>
            <div className='d-flex flex-column justify-content-between content-height'>
              <div className="justify-content-center">
                <Switch>
                  <Route exact={true} path="/" component={CommunitySelection} />
                  {/* <Route exact={true} path="/" component={LandingPage} /> */}
                  <Route exact={true} path="/referral" component={CommunitySelection} />
                  <Route exact={true} path="/st" component={CommunitySelection} />
                  <Route exact={true} path="/stall" component={CommunitySelection} />
                  <Route exact={true} path="/:code" component={CustomerDashboard} />
                  <Route exact={true} path="/:code/al/:id" component={CustomerDashboard} />
                  <Route path="/:code/stallhome/:serviceId" component={StallHome} />
                  <Route path="/:code/listingstallhome/:serviceId" component={ListingStalls} />
                  <Route path="/:code/stall/:serviceId/:stallId/:saleOrBookingId?/:from?/:parent" component={Stall} />
                  <Route path="/:code/comingsoon" component={ComingSoon} />
                  <Route path="/:code/listingservicehome/:serviceId" component={ListingServiceHome} />
                  <Route path="/:code/childcarelistinghome/:serviceId" component={ChildCareListingHome} />
                  <Route path="/:code/review/:tk" component={ReviewForm} />
                  <Route path="/:code/myaccount" component={MyAccountContainer} />
                  <Route path="/:code/localdirectory" component={LocalDrCard} />
                  <Route path="/:code/orderconfirmation" component={OrderConfirmationContainer} />
                  <Route path="/:code/ordersummary/:orderId" component={OrderSummary} />
                  <Route path="/:code/communityposts" component={CommunityPosts} />
                  <Route path="/:code/listingordersummary/:saleOrderId" component={ListingOrderSummary} />
                  <Route path="/:code/sale/:serviceId/:saleId/:parent" component={ListingSale} />
                  <Route path="/:code/provider/providerorders" component={ProviderOrderContainer} />
                  <Route path="/:code/provider/createservicerequest/:serviceId" component={CreateServiceRequestContainer} />
                  <Route path="/:code/provider/providerservices" component={ProviderServices} />
                  <Route path="/:code/provider/mystalls" component={MyStallList} />
                  <Route path="/:code/provider/manageitems" component={ManageItems} />
                  <Route path="/:code/provider/providerservicerequests" component={ProviderServiceRequests} />
                  <Route path="/:code/provider/stallitembatchlist/:id" component={StallItemBatchList} />
                  <Route path="/:code/provider/stallitemcustomize/:id" component={StallItemCustomize} />
                  <Route path="/:code/provider/providerdashboard" component={ProviderDashboard} />
                  <Route path="/:code/provider/providerordercontainer/:stallId/:from/:type" component={ProviderOrderContainer} />
                  <Route path="/:code/provider/stallorders/:id/:type" component={StallOrders} />
                  <Route path="/:code/provider/managelistings" component={ManageListings} />
                  <Route path="/:code/provider/saleitemform/:id" component={SaleItemForm} />
                  <Route path="/:code/provider/dashboardsaleorders/:id/:type" component={DashboardSaleOrders} />
                  <Route path="/:code/provider/stalllistings/:id" component={StallListings} />
                  <Route path="/:code/provider/provdashboard/:id" component={ProviderDashboard} />
                  <Route path="/:code/myaccountprofile/:from/:msg" component={MyAccountContainer} />
                  <Route path="/:code/myaccountinvite/:from" component={MyAccountContainer} />
                  <Route path="/:code/provider/livestreams" component={LiveStreamList} />
                  <Route path="/:code/provider/livestreamdetail/:lh" component={LiveStreamDetail} />
                  <Route path="/:code/community/providerlist" component={ProviderList} />
                  <Route path="/:code/lv/:lh" component={LiveStreamLanding} />
                  <Route path="/:code/provider/livestreamdetailinfo/:lh/:name" component={LiveStreamDetail} />
                  <Route path="/:code/lvm/:lh/:uh" component={LiveStreamLanding} />
                  <Route path="/:code/provider/followedusers/:id" component={FollowedUsers} />
                  <Route path="/:code/provider/subscriptions/:id/:from" component={Subscriptions} />
                  <Route path="/:code/superadmin/webcommunitylist" component={CommunityList} />
                  <Route path="/:code/zone/webzonedashboard" component={ZoneDashboard} />
                  <Route path="/:code/region/webregiondashboard" component={RegionDashboard} />
                  <Route path="/:code/region/webregioncommunitylist" component={RegionCommunityList} />
                  <Route path="/:code/provider/accounts/:id/:from" component={ProviderAccountList} />
                  <Route path="/:code/community/couponlist" component={FeatureCouponList} />
                  <Route path="/:code/community/pendingpaymentlist" component={PendingPaymentList} />
                  <Route path="/:code/zone/webzonalcommunitylist" component={ZonalCommunityList} />
                  <Route path="/:code/sale/:saleId/:parent" component={ListingSale} />
                  <Route path="/:code/specialevents" component={SpecialEvents} />
                  <Route path="/:code/localdrlist/:id" component={ProviderLocalDrList} />
                  <Route path="/:code/provider/mypayments" component={MyPayments} />
                  <Route path="/:code/community/communityadmindashboard" component={CommunityAdminDashboard} />
                  <Route path="/:code/community/servicerequestlist" component={ServiceRequestList} />
                  <Route path="/:code/community/userlist" component={UserList} />
                  <Route path="/:code/community/stalllist" component={StallList} />
                  <Route path="/:code/community/reviewlist" component={CommunityReviews} />
                  <Route path="/:code/community/truefansboard" component={TrueFansBoard} />
                  <Route path="/:code/community/orders" component={CommunityItemOrders} />
                  <Route path="/:code/community/items" component={ItemList} />
                  <Route path="/:code/community/categories" component={CategoryList} />
                  <Route path="/:code/community/profile" component={CommunityProfile} />
                  <Route path="/:code/community/itemtags" component={ItemTagList} />
                  <Route path="/:code/community/payments" component={PaymentsContainer} />
                  <Route path="/:code/community/income" component={CommunityMonthlyIncome} />
                  <Route path="/:code/community/truefans" component={ManagersTrueFansBoard} />
                  <Route path="/:code/community/reviews" component={ManagersReviews} />
                  <Route path="/:code/community/speciallist" component={SpecialCommunityList} />
                  <Route path="/:code/provider/webspecialevents" component={SpecialEventList} />
                  <Route path="/:code/community/specialpayments" component={SpecialCommunityPayments} />
                  <Route path="/:code/community/services" component={CommunityServices} />
                  <Route path="/:code/superadmin/webservicelist" component={ServiceList} />
                  <Route path="/:code/provider/managebookings" component={ManageBookings} />
                  <Route path="/:code/bookingservicehome/:serviceId" component={BookingServiceHome} />
                  <Route path="/:code/provider/stallbookings/:id" component={StallBookings} />
                  <Route path="/:code/:serviceSlug/itemwise/stalls" component={StallHome} />
                  <Route path="/:code/:serviceSlug/listings/stalls" component={ListingStalls} />
                  <Route path="/:code/:serviceSlug/bookings/stalls" component={BookingServiceHome} />
                  <Route path="/:code/stalls/:stallSlug" component={Stall} />
                  <Route path="/:code/account-deletion" component={AccountDeletion} />
                  <Route path="/:code/contact" component={ContactUs} />
                </Switch>
              </div>
              <div>
                <Footer />
              </div>
            </div>
            <ToastContainer />
          </div>
        </Router>
      </ProgressProvider>
    </MuiThemeProvider>
  );
}

export default App;
